export const conditions = [
  'Depression',
  "Crohn's Disease, Maintenance",
  'Urinary Tract Infection',
  'Weight Loss',
  'Birth Control',
  'Keratosis',
  'Migraine Prevention',
  'Opiate Withdrawal',
  'Hot Flashes',
  'Vaginal Yeast Infection',
  'Schizoaffective Disorde',
  'Insomnia',
  'Narcolepsy',
  'Smoking Cessation',
  'Acne',
  'Bipolar Disorde',
  'Irritable Bowel Syndrome',
  'Keratoconjunctivitis Sicca',
  'Hyperhidrosis',
  'Panic Disorde',
  'Onychomycosis, Fingernail',
  'Rosacea',
  'Bowel Preparation',
  'Constipation, Drug Induced',
  'Diabetes, Type 2',
  'Pain',
  'Lipodystrophy',
  'Alcohol Dependence',
  'Emergency Contraception',
  'Major Depressive Disorde',
  'Anxiety',
  'Bladder Infection',
  'Benign Prostatic Hyperplasia',
  'Rheumatoid Arthritis',
  'Endometriosis',
  'ADHD',
  'Restless Legs Syndrome',
  'Conjunctivitis, Allergic',
  "Raynaud's Syndrome",
  'Schizophrenia',
  'Diabetes, Type 1',
  'Kidney Infections',
  'Generalized Anxiety Disorde',
  'Obesity',
  'Nausea/Vomiting',
  'Osteoarthritis',
  'Motion Sickness',
  'High Blood Pressure',
  'Psoriatic Arthritis',
  'Interstitial Cystitis',
  'Postmenopausal Symptoms',
  'Bacterial Skin Infection',
  'ibromyalgia',
  'Cough and Nasal Congestion',
  'Diabetic Peripheral Neuropathy',
  'Renal Cell Carcinoma',
  'Constipation',
  'Social Anxiety Disorde',
  'Nausea/Vomiting of Pregnancy',
  'Binge Eating Disorde',
  'Hepatitis C',
  'Back Pain',
  'Cold Sores',
  'Opiate Dependence',
  'Heart Attack',
  'Asthma',
  'Cough',
  'Erectile Dysfunction',
  'Autoimmune Hepatitis',
  'Constipation, Chronic',
  'Seizures',
  'Bacterial Vaginitis',
  'Headache',
  'Anesthesia',
  'mance Anxiety',
  'Menstrual Disorders',
  'Pneumonia',
  'Constipation, Acute',
  'Chronic Pain',
  'Benign Essential Trem',
  'Human Papilloma Virus',
  'Hypersomnia',
  'Diabetic Kidney Disease',
  "Meniere's Disease",
  'Nasal Congestion',
  'Obsessive Compulsive Disorde',
  'Chronic Myelogenous Leukemia',
  'emale Infertility',
  'Oral Thrush',
  'Actinic Keratosis',
  'HIV Infection',
  'Vitamin/Mineral Supplementation during Pregnancy/Lactation',
  'Bacterial Infection',
  'Vitamin D Deficiency',
  'Sciatica',
  'Otitis Media',
  'Skin or Soft Tissue Infection',
  "Crohn's Disease",
  'GERD',
  'Labor Induction',
  'Myasthenia Gravis',
  'Psoriasis',
  'Soft Tissue Sarcoma',
  'Osteoporosis',
  'Ovarian Cysts',
  'Giardiasis',
  'Diverticulitis',
  'Influenza',
  'Abnormal Uterine Bleeding',
  'Anxiety and Stress',
  'Sinusitis',
  'Gastroenteritis',
  'Period Pain',
  'Inflammatory Conditions',
  'Muscle Pain',
  'Allergic Rhinitis',
  'Neutropenia Associated with Chemotherapy',
  'Herpes Simplex, Suppression',
  'Multiple Sclerosis',
  'Vertig',
  'Muscle Spasm',
  'Bronchitis',
  'Autism',
  'Alpha-1 Proteinase Inhibitor Deficiency',
  'High Cholesterol',
  'Urticaria',
  'Systemic Lupus Erythematosus',
  'Diarrhea',
  "Crohn's Disease, Acute",
  'Migraine',
  'Pulmonary Hypertension',
  'Breast Cance',
  'Seizure Prevention',
  'Borderline Personality Disorde',
  'Rat-bite Feve',
  'COPD, Maintenance',
  'Alopecia',
  'Dysuria',
  'Warts',
  'Leukemia',
  'Underactive Thyroid',
  "Still's Disease",
  "Hashimoto's disease",
  'Mitral Valve Prolapse',
  'Epilepsy',
  'Hypogonadism, Male',
  'Overactive Bladde',
  'Peripheral Neuropathy',
  'Glaucoma, Open Angle',
  'Onychomycosis, Toenail',
  'Cluster Headaches',
  'Ulcerative Colitis',
  'Asthma, Maintenance',
  'Deep Vein Thrombosis',
  'min / sitagliptin)',
  'Post Traumatic Stress Disorde',
  'Bursitis',
  'Prostatitis',
  'Postoperative Pain',
  'Human Papillomavirus Prophylaxis',
  'Chlamydia Infection',
  'Ulcerative Colitis, Active',
  'me',
  'Computed Tomography',
  'Herpes Zoster, Prophylaxis',
  'Supraventricular Tachycardia',
  'Indigestion',
  'Gout, Acute',
  'Nocturnal Leg Cramps',
  'Prostate Cance',
  'Anal Fissure and Fistula',
  "Parkinson's Disease",
  'Premenstrual Dysphoric Disorde',
  'atigue',
  'Urinary Incontinence',
  'COPD',
  'Duodenal Ulce',
  "Alzheimer's Disease",
  'Atrial Fibrillation',
  'Basal Cell Carcinoma',
  "Barrett's Esophagus",
  'Androgenetic Alopecia',
  'Hypothyroidism, After Thyroid Removal',
  'Ankylosing Spondylitis',
  'Postpartum Depression',
  'Opioid Overdose',
  'Prevention of Bladder infection',
  'Infection Prophylaxis',
  'Perimenopausal Symptoms',
  'Swine Flu',
  'Dry Eye Disease',
  'Ulcerative Proctitis',
  'Organ Transplant, Rejection Prophylaxis',
  'Reflex Sympathetic Dystrophy Syndrome',
  'Alcohol Withdrawal',
  'Benzodiazepine Withdrawal',
  'Heart Failure',
  'High Cholesterol, Familial Heterozygous',
  'Dental Abscess',
  'Pe',
  'Herpes Simplex',
  'Melanoma, Metastatic',
  "Non-Hodgkin's Lymphoma",
  'Nightmares',
  'Non-Small Cell Lung Cance',
  'Postherpetic Neuralgia',
  'Cold Symptoms',
  'Atopic Dermatitis',
  'Rhinitis',
  'Eczema',
  'Herbal Supplementation',
  'Sedation',
  'Pharyngitis',
  'Burns, External',
  'Menorrhagia',
  'Hypertriglyceridemia',
  'Tonsillitis/Pharyngitis',
  'Opioid-Induced Constipation',
  'Plaque Psoriasis',
  'Urinary Tract Stones',
  'Left Ventricular Dysfunction',
  'Anemia, Chemotherapy Induced',
  "Traveler's Diarrhea",
  'Extrapyramidal Reaction',
  'Dermatitis',
  'Upper Respiratory Tract Infection',
  'Angina',
  'Hypodermoclysis',
  'Pneumococcal Disease Prophylaxis',
  'Hirsutism',
  'Sexual Dysfunction, SSRI Induced',
  'Hemorrhoids',
  'Prevention of Thromboembolism in Atrial Fibrillation',
  'Helicobacter Pylori Infection',
  'Occipital Neuralgia',
  'Stomach Ulce',
  'Uveitis, Posteri',
  "Sjogren's Syndrome",
  'Neuropathic Pain',
  'Neuralgia',
  'Light Sedation',
  'Shift Work Sleep Disorde',
  'Vitamin/Mineral Supplementation and Deficiency',
  'Paranoid Disorde',
  'Diarrhea, Chronic',
  'Breast Cancer, Metastatic',
  'Nasal Polyps',
  'Chronic Fatigue Syndrome',
  'Allergic Reactions',
  'Asthma, acute',
  'Polycystic Ovary Syndrome',
  'Dietary Supplementation',
  'Dermatologic Lesion',
  'Edema',
  'Strabismus',
  'Hyperlipoproteinemia Type IIa, Elevated LDL',
  'Gas',
  'Tinea Pedis',
  'Secondary Hyperparathyroidism',
  'Conjunctivitis',
  'Prevention of Osteoporosis',
  'B12 Nutritional Deficiency',
  'Opiate Adjunct',
  'Platelet Aggregation Inhibition',
  "Wegener's Granulomatosis",
  "Tourette's Syndrome",
  'Atrophic Vaginitis',
  'Dermatological Disorders',
  'Glaucoma',
  'Precocious Puberty',
  'Diaper Rash',
  'Juvenile Rheumatoid Arthritis',
  'Vulvodynia',
  'cal Segmental Glomerulosclerosis',
  'Hypotension',
  'Glioblastoma Multi',
  'Condylomata Acuminata',
  'Organ Transplant, Rejection Reversal',
  'Otitis Externa',
  'Trigeminal Neuralgia',
  'Strep Throat',
  'Sinus Symptoms',
  'Breast Cancer, Adjuvant',
  'Premature Ejaculation',
  'Obstructive Sleep Apnea/Hypopnea Syndrome',
  'Acute Coronary Syndrome',
  'Hyperprolactinemia',
  'Diabetes Insipidus',
  'Deep Vein Thrombosis Prophylaxis after Hip Replacement Surgery',
  'Allergies',
  'Postoperative Ocular Inflammation',
  'Auditory Processing Disorde',
  'Anemia, Sickle Cell',
  'Chronic Idiopathic Constipation',
  'Eye Redness/Itching',
  'Anorexia',
  'Arrhythmia',
  'Gastroparesis',
  "Dercum's Disease",
  'Percutaneous Coronary Intervention',
  'Tinnitus',
  'Cystic Fibrosis',
  'Polymyositis/Dermatomyositis',
  'Thromboembolic Stroke Prophylaxis',
  'Dry Skin',
  'Methicillin-Resistant Staphylococcus Aureus Infection',
  'Thrombocythemia',
  'Ear Wax Impaction',
  'Cyclic Vomiting Syndrome',
  'Acute Lymphoblastic Leukemia',
  'Macular Degeneration',
  'Gouty Arthritis',
  'Psychosis',
  'Mononucleosis',
  'Gastric Ulcer Maintenance Treatment',
  'Head Lice',
  'Hereditary Angioedema',
  'Breast Cancer, Prevention',
  'Cervical Dystonia',
  'Mucositis',
  'Melasma',
  'Corneal Refractive Surgery',
  'CMV Prophylaxis',
  'Gastrointestinal Hemorrhage',
  'Agitated State',
  'Insulin Resistance Syndrome',
  'Bacterial Endocarditis Prevention',
  'Spondyloarthritis',
  'acial Wrinkles',
  'Reversal of Opioid Sedation',
  'Lymphocytic Colitis',
  'Bulimia',
  'Pertussis',
  'Pulmonary Embolism',
  'Gout',
  'Pinworm Infection (Enterobius vermicularis)',
  'Lennox-Gastaut Syndrome',
  'Intraocular Hypertension',
  'Conjunctivitis, Bacterial',
  'Uveitis',
  'Hidradenitis Suppurativa',
  'Hyperphosphatemia of Renal Failure',
  'Gonococcal Infection, Uncomplicated',
  'Macular Edema',
  'Pancreatic Exocrine Dysfunction',
  'Uterine Fibroids',
  'Xerostomia',
  'Premenstrual Syndrome',
  'Premature Lab',
  'Angina Pectoris Prophylaxis',
  'Intermittent Claudication',
  'Hyperekplexia',
  'Juvenile Idiopathic Arthritis',
  'Dumping Syndrome',
  'Iron Deficiency Anemia',
  'Mania',
  'Chronic Lymphocytic Leukemia',
  'Temporomandibular Joint Disorde',
  'Myelodysplastic Syndrome',
  'Pelvic Inflammatory Disease',
  'Primary Immunodeficiency Syndrome',
  'Nausea/Vomiting, Postoperative',
  'Gender Dysphoria',
  'Ischemic Stroke, Prophylaxis',
  'Bronchospasm Prophylaxis',
  'Mild Cognitive Impairment',
  'Skin and Structure Infection',
  'Vitamin B12 Deficiency',
  'Periodic Limb Movement Disorde',
  'Glioblastoma Multiforme',
  'Hypoestrogenism',
  'Keratitis',
  'Immunosuppression',
  'Lymphoma',
  'Herpes Simplex, Mucocutaneous/Immunocompetent Host',
  'Seasonal Allergic Conjunctivitis',
  'Expectoration',
  'Atrophic Urethritis',
  'Tinea Cruris',
  'Burning Mouth Syndrome',
  'Amenorrhea',
  'Gingivitis',
  'Skin Cance',
  'Lactose Intolerance',
  'Tendonitis',
  'Ulcerative Colitis, Maintenance',
  'Diagnosis and Investigation',
  'Schnitzler Syndrome',
  'Deep Vein Thrombosis, Prophylaxis',
  'Ovulation Induction',
  'Malaria',
  'Labor Pain',
  'lic Acid Deficiency',
  'Light Anesthesia',
  'Hepatic Encephalopathy',
  'Chronic Pancreatitis',
  'Abortion',
  'Hypertensive Emergency',
  'Dietary Fiber Supplementation',
  'Biliary Cirrhosis',
  'Eye Redness',
  'Malaria Prevention',
  'TSH Suppression',
  'Undifferentiated Connective Tissue Disease',
  'Cerebral Spasticity',
  'Melanoma',
  'Tinea Versicol',
  'Trichomoniasis',
  'Nausea/Vomiting, Chemotherapy Induced',
  'Hiccups',
  'Peptic Ulce',
  'Clostridial Infection',
  'Pruritus',
  'Photoaging of the Skin',
  'Herpes Zoste',
  'Breakthrough Pain',
  'Ventricular Tachycardia',
  'Primary Ovarian Failure',
  'Liver Magnetic Resonance Imaging',
  'Bacteremia',
  'Inflammatory Bowel Disease',
  'Pupillary Dilation',
  'Post-Cholecystectomy Diarrhea',
  'Esophageal Variceal Hemorrhage Prophylaxis',
  'COPD, Acute',
  "von Willebrand's Disease",
  'Lactation Augmentation',
  'Bone infection',
  'Oophorectomy',
  'Progesterone Insufficiency',
  'ge (amlodipine / valsartan)',
  'Pseudobulbar Affect',
  'Atrial Flutte',
  'Sarcoidosis',
  'zen Shoulde',
  'Lyme Disease, Neurologic',
  'AIDS Related Wasting',
  'Asperger Syndrome',
  'Jet Lag',
  'Night Terrors',
  'Hypokalemic Periodic Paralysis',
  'Thyroid Cance',
  'Lyme Disease',
  'Dysautonomia',
  'Spondylolisthesis',
  'llicle Stimulation',
  'Hypocalcemia',
  'Zollinger-Ellison Syndrome',
  'Coccidioidomycosis',
  'Myotonia Congenita',
  'Anemia Associated with Chronic Renal Failure',
  'Polycythemia Vera',
  'Radionuclide Myocardial Perfusion Study',
  'Idiopathic Thrombocytopenic Purpura',
  'Persistent Depressive Disorde',
  'Mycobacterium avium-intracellulare, Treatment',
  'Oral and Dental Conditions',
  'Seborrheic Dermatitis',
  'Gastrointestinal Decontamination',
  'ICU Agitation',
  'High Cholesterol, Familial Homozygous',
  'Erosive Esophagitis',
  'Pseudotumor Cerebri',
  'Agitation',
  'Aplastic Anemia',
  'Coronary Artery Disease',
  'm Pain Disorde',
  'Dissociative Identity Disorde',
  'Bullous Pemphigoid',
  'Surgical Prophylaxis',
  'Body Dysmorphic Disorde',
  'Colorectal Cance',
  'Lichen Planus',
  'Ventricular Fibrillation',
  'Impetig',
  'Tinea Corporis',
  'Prevention of Cardiovascular Disease',
  'Varicella-Zoste',
  'Allergic Urticaria',
  'eve',
  'Noninfectious Colitis',
  'Hemorrhoids (pramoxine / zinc oxide)',
  'Enterocolitis',
  'Chronic Spasticity',
  'Primary Nocturnal Enuresis',
  'Premature Ventricular Depolarizations',
  'Skin Rash',
  'Pancreatic Cance',
  'Skin Disinfection, Preoperative',
  'Transient Ischemic Attack',
  'Mixed Connective Tissue Disease',
  'Cutaneous T-cell Lymphoma',
  'Nephrotic Syndrome',
  'Aphthous Ulce',
  'Pulmonary Embolism, First Event',
  'Pemphigus',
  'Gastritis/Duodenitis',
  'Salmonella Gastroenteritis',
  'Somat',
  'Mycoplasma Pneumonia',
  'Women (oxybutynin)',
  'Ophthalmic Surgery',
  'Ventricular Arrhythmia',
  'Anemia',
  'Peritonitis',
  'Pityriasis rubra pilaris',
  'Hepatocellular Carcinoma',
  'Iritis',
  'Gastrointestinal Stromal Tum',
  'Rhinorrhea',
  'Adrenocortical Insufficiency',
  'Sore Throat',
  'Diarrhea, Acute',
  'Hypomagnesemia',
  'Endometrial Hyperplasia',
  'Diabetic Macular Edema',
  'Hypoparathyroidism',
  'Costochondritis',
  'Autoimmune Hemolytic Anemia',
  'Osteolytic Bone Lesions of Multiple Myeloma',
  'Cutaneous Candidiasis',
  'Hypoactive Sexual Desire Disorde',
  'Mountain Sickness / Altitude Sickness',
  'Seasonal Affective Disorde',
  'Postural Orthostatic Tachycardia Syndrome',
  'Cyclitis',
  'Pre-Exposure Prophylaxis',
  'Deep Vein Thrombosis, First Event',
  "Paget's Disease",
  'Dandruff',
  'Aspergillosis, Aspergilloma',
  "Addison's Disease",
  'Neck Pain',
  'Nasal Carriage of Staphylococcus aureus',
  'Tuberculosis, Latent',
  'Stress Ulcer Prophylaxis',
  'Subarachnoid Hemorrhage',
  'Dyspareunia',
  'Antiphospholipid Syndrome',
  'Osteolytic Bone Metastases of Solid Tumors',
  'Dystonia',
  'Neutropenia',
  'Systemic Sclerosis',
  'Histoplasmosis',
  'Nausea (phosphorated carbohydrate solution)',
  'Schilling Test',
  'Influenza Prophylaxis',
  'Lichen Sclerosus',
  'Trichotillomania',
  'Prevention of Hypokalemia',
  'Menopausal Disorders',
  'Performance Anxiety',
  'Chronic Inflammatory Demyelinating Polyradiculoneuropathy',
  'Peripheral Arterial Disease',
  'Multiple Myeloma',
  "Behcet's Disease",
  'Hepatitis B',
  'Intraabdominal Infection',
  'Local Anesthesia',
  "Dupuytren's contracture",
  'Occupational Exposure',
  'Anal Itching',
  'Meningococcal Meningitis Prophylaxis',
  'Hyperlipoproteinemia',
  'Tympanostomy Tube Placement Surgery',
  'Upper Limb Spasticity',
  'Gallbladder Disease',
  'Postoperative Increased Intraocular Pressure',
  'Cyclothymic Disorde',
  'Dermatomyositis',
  'Deep Neck Infection',
  'Anthrax',
  'Endometrial Cance',
  'Pulmonary Edema',
  'Ischemic Stroke',
  'Mantle Cell Lymphoma',
  'Streptococcal Infection',
  'Myeloproliferative Disorders',
  'Systemic Mastocytosis',
  'Acetaminophen Overdose',
  'Pediatric Growth Hormone Deficiency',
  'Dementia',
  'Chronic Myofascial Pain',
  'Giant Cell Tumor of Bone',
  'Syringomyelia',
  'Oligospermia',
  'Ehrlichiosis',
  'mulation) (phenylephrine)',
  'Lyme Disease, Erythema Chronicum Migrans',
  'Scabies',
  'Perioral Dermatitis',
  'Insomnia, Stimulant-Associated',
  'Herpes Simplex Dendritic Keratitis',
  'Acute Promyelocytic Leukemia',
  'Blepharitis',
  'Topical Disinfection',
  'Gonadotropin Inhibition',
  'Squamous Cell Carcinoma',
  'Deep Vein Thrombosis, Recurrent Event',
  'Uterine Bleeding',
  'ibrocystic Breast Disease',
  'Ovarian Cance',
  'Bleeding Disorde',
  'New Daily Persistent Headache',
  'Amebiasis',
  'Eyelash Hypotrichosis',
  'Hyperkalemia',
  'amilial Cold Autoinflammatory Syndrome',
  'NSAID-Induced Gastric Ulce',
  'Pemphigoid',
  'Adult Human Growth Hormone Deficiency',
  'Hyperthyroidism',
  'Renal Tubular Acidosis',
  'Cance',
  'Periodontitis',
  'Wolff-Parkinson-White Syndrome',
  'Urinary Retention',
  'Head Injury',
  'Sunburn',
  'Hypercalcemia of Malignancy',
  'Prevention of Dental Caries',
  'Dermatophytosis',
  'Hemophilia A',
  'Eosinophilic Esophagitis',
  'Primary Hyperaldosteronism',
  'Breast Cancer, Palliative',
  'Oppositional Defiant Disorde',
  'tic (mycophenolic acid)',
  'Submental Fullness',
  'Parkinsonism',
  'Reversal of Nondepolarizing Muscle Relaxants',
  'Nonoccupational Exposure',
  'Cerebral Edema',
  'Yellow Fever Prophylaxis',
  'acial Lipoatrophy',
  'Cataplexy',
  'Cachexia',
  'Stomach Cance',
  'Joint Infection',
  'Status Epilepticus',
  'Pudendal Neuralgia',
  'Acute Otitis Externa',
  'Small Bowel or Pancreatic Fistula',
  'Steroid Responsive Inflammatory Conditions',
  'Hyperphosphatemia',
  'Myelofibrosis',
  'Hemophilia B',
  'Pain/Feve',
  'Thyrotoxicosis',
  'Pertussis Prophylaxis',
  'mist (',
  'Head and Neck Cance',
  'Herpes Simplex, Mucocutaneous/Immunocompromised Host',
  'actor IX Deficiency',
  'Ichthyosis',
  'Anaplastic Astrocytoma',
  'Acute Nonlymphocytic Leukemia',
  'Epididymitis, Sexually Transmitted',
  'Strongyloidiasis',
];
